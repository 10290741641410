
import countryList from "@/constant/countryList"
import Highcharts from 'highcharts'
import moment from "moment";
import numeral from 'numeral';
const colorSun = '#f00';
const colorSat = '#fd7e14';
const colorNormal = '#000';
const countSpline = {
	chart: {
		type: 'spline',
		zoomType: 'x',
		panning: true,
		panKey: 'shift',
		scrollablePlotArea: {
			minWidth: 100
		},
		marginRight: 30,
		animation: Highcharts.svg
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	yAxis: {
		title: {
			text: '',
		},
		labels: {
			formatter: function () {
				return Highcharts.numberFormat(this.value, 0);
			}
		}
	},
	xAxis: {
		categories: [],
		title: {
			text: '',
		},
		allowDecimals: false,
		tickInterval: 1,
		labels: {
			formatter: function () {
				let color = (moment.utc(this.value, 'DD-MM-YYYY').day() === 0) ? colorSun : ((moment.utc(this.value, 'DD-MM-YYYY').day() === 6) ? colorSat : colorNormal)
				return `<span style="fill: ${color};">${this.value}</span>`;
			}
		}
	},
	legend: { enabled: true },
	plotOptions: {
		spline: {
			dataLabels: {
				enabled: true
			},
		},
		series: {
			tooltip: {
				headerFormat: '{point.x} <b>{series.name}</b><br>',
				pointFormat: 'Used {point.y} times',
				clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			},
		},
		line: {
			dataLabels: {
				enabled: true
			},
			enableMouseTracking: false,
			marker: {
				fillColor: Highcharts.getOptions().colors[2],
				lineColor: Highcharts.getOptions().colors[2],
				enabled: false
			}
		}
	},
	series: []
}

const bootingMachine = Object.assign(JSON.parse(JSON.stringify(countSpline)), {
	plotOptions: {
		series: {
			tooltip: {
				headerFormat: '{point.x} <b>{series.name}</b><br>',
				pointFormat: 'Booting {point.y} times',
				clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			},
		},
	},
});


const areaSplineChart = Object.assign(JSON.parse(JSON.stringify(countSpline)), {
	chart: {
		type: 'areaspline',
	},
	plotOptions: {
		areaspline: {
			dataLabels: {
				enabled: true
			},
			fillColor: {
				linearGradient: {
					x1: 0,
					y1: 0,
					x2: 0,
					y2: 1
				},
				stops: [
					[0, Highcharts.getOptions().colors[3]],
					[1, Highcharts.Color(Highcharts.getOptions().colors[8]).setOpacity(0.2).get('rgba')]
				]
			},
			marker: {
				fillColor: Highcharts.getOptions().colors[1],
				lineColor: Highcharts.getOptions().colors[1],
				enabled: true
			},
			lineColor: '#303030'
		},
		series: {
			tooltip: {
				headerFormat: '{point.x}<br>',
				pointFormat: '<b>{point.y} {series.name}</b>',
				clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			},
		},
	},
});


const totalRechargeOptions = Object.assign(JSON.parse(JSON.stringify(countSpline)), {
	plotOptions: {
		series: {
			tooltip: {
				headerFormat: '{point.x} <br>',
				pointFormat: 'Users of <b>{series.name}</b> recharge total ${point.y}',
				clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			},
		},
	},
});


const totalUsingTimeChartOptions = Object.assign(JSON.parse(JSON.stringify(countSpline)), {
	chart: {
		type: 'areaspline',
	},
	plotOptions: {
		areaspline: {
			dataLabels: {
				enabled: true
			},
			fillColor: {
				linearGradient: {
					x1: 0,
					y1: 0,
					x2: 0,
					y2: 1
				},
				stops: [
					[0, Highcharts.getOptions().colors[3]],
					[1, Highcharts.Color(Highcharts.getOptions().colors[8]).setOpacity(0.2).get('rgba')]
				]
			},
			marker: {
				fillColor: Highcharts.getOptions().colors[1],
				lineColor: Highcharts.getOptions().colors[1],
				enabled: true
			},
			lineColor: '#303030'
		},
		series: {
			tooltip: {
				headerFormat: '{point.x}<br>',
				pointFormat: '<b>{point.y} Hours</b>',
				clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			},
		},
	}
});

const circleChart = {
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		plotShadow: false,
		type: 'pie'
	},
	colors: Highcharts.getOptions().colors.map(function(color) {
		return {
			radialGradient: {
				cx: 0.5,
				cy: 0.3,
				r: 0.7
			},
			stops: [
				[0, color],
				[1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
			]
		};
	}),
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	tooltip: {
		pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
	},
	accessibility: {
		point: {
			valueSuffix: '%'
		}
	},
	plotOptions: {
		pie: {
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: true,
				format: '<b>{point.name}</b>: {point.percentage:.2f} %',
				connectorColor: 'silver'
			}
		}
	},
	series: [{
		name: 'Share',
		data: []
	}]
}

const barChartHoz = {
	chart: {
		height: 1600,
		type: 'bar'
	},
	title: {
		text: '',
    },
	xAxis: {
		categories: [],
		title: {
			text: null
		},
		labels: {
			useHTML: true,
			animate: true,
			formatter: function () {
				var value = this.value;
				console.log('xAxis', value);
				return `<a href='/paid-users?targetView=regionFilter&targetRegion=${(value.region === 'FPTSOFT' ? 'VN' : value.region)}' target='_blank' >
                            <img src="/flag/${value.region}.svg" style="width: 15px; height: 15px; margin-right: 5px;"/>&nbsp;
                            <span style="display: inline-block; width: 125px;">${(value.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (countryList[value.region] ? countryList[value.region] : "N/A"))} (${value.countUser})
                            </span>
                        </a>`;
			}
		}
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Recharge ($)',
			align: 'high'
		},
		labels: {
			overflow: 'justify'
		}
	},
	tooltip: {
		formatter: function () {
			var value = this.value;
			return 'The revenue for <b>' + (value.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (countryList[value.region] ? countryList[value.region] : "N/A")) +
                '</b> is <b>' + numeral(this.y).format('0,0.[0000]') + '$</b>';
        },
		valuePrefix: '$'
	},
	plotOptions: {
		bar: {
			dataLabels: {
				enabled: true,
				formatter: function () {
					return `$${numeral(this.y).format('0,0.[0000]')}`;
				}
			}
		}
	},
	legend: { enabled: false },
	credits: {
		enabled: false
	},
	series: [{
		data: [1216, 1001, 4436, 738, 40]
	}]
};

export {
	countSpline, areaSplineChart, bootingMachine, totalRechargeOptions, totalUsingTimeChartOptions, circleChart,
	colorSun, colorSat, colorNormal, barChartHoz
}