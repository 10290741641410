import { chartDataCnf } from '@/constant/config.js';
import axios from "axios";
export default {
    getMachineUserdChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getMachineUserdChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getMachineBootingChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getMachineBootingChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getUserCreatedChartData(numberOfDateView = 30, targetCountry = null) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getUserCreatedChartData.format(numberOfDateView)}?targetCountry=${targetCountry}`,
            withCredentials: true,
        });
    },
    getUserRechargeMoreThanOneChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getUserRechargeMoreThanOneChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getMachineCreatedChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getMachineCreatedChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getTotalRechargeChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getTotalRechargeChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getTotalRechargeByStaffChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getTotalRechargeByStaffChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getTotalUsingTimeChartData(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getTotalUsingTimeChartData.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getCountUserRechargeByLevel(numberOfDateView = 30, source = "") {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getCountUserRechargeByLevel.format(numberOfDateView, source)}`,
            withCredentials: true,
        });
    },
    getCountMasterNodeByCategory(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getCountMasterNodeByCategory.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getCountMasterNodeBySoftware(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getCountMasterNodeBySoftware.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getPercentMasterNodeByCategory() {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getPercentMasterNodeByCategory}`,
            withCredentials: true,
        });
    },
    getPercentMasterNodeBySoftware() {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getPercentMasterNodeBySoftware}`,
            withCredentials: true,
        });
    },
    getCountBlackListUser(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getCountBlackListUser.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getCountPaidUserWithRecharge(domesticOrInternational, numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getCountPaidUserWithRecharge.format(domesticOrInternational, numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getBootingAvgStatisticsByDate(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getBootingAvgStatisticsByDate.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getBootingSpentShutdownAvgStatisticsByDate(typeData, numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getBootingSpentShutdownAvgStatisticsByDate.format(typeData, numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getRevenueByCountry(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getRevenueByCountry.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getRevenueDetailOfRegion(request) {
        return axios({
            method: "POST",
            url: chartDataCnf.getRevenueDetailOfRegion,
            data: request,
            withCredentials: true,
        });
    },
    getUserByCountry(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getUserByCountry.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getTop10RegionRevenueAndUser(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getTop10RegionRevenueAndUser.format(numberOfDateView)}`,
            withCredentials: true,
        });
    },
    getUserByTop10Country(requestData) {
        return axios({
            method: "POST",
            url: `${chartDataCnf.getUserByTop10Country}`,
            data: requestData,
            withCredentials: true,
        });
    },
    getSoftwareInstalledStatistics() {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getSoftwareInstalledStatistics}`,
            withCredentials: true,
        });
    },
    getProccessStatistics() {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getProccessStatistics}`,
            withCredentials: true,
        });
    },
    getStatisticsUserRechargeAfterRegister() {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getStatisticsUserRechargeAfterRegister}`,
            withCredentials: true,
        });
    },
    getRevenueByMonth(numberOfMonth, isForceNew = false) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getRevenueByMonth.format(numberOfMonth)}${isForceNew ? "?forceNew=true" : ""}`,
            withCredentials: true,
        });
    },
    getCountUserRechargeLevelByMonth(numberOfMonth) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getCountUserRechargeLevelByMonth.format(numberOfMonth)}`,
            withCredentials: true,
        });
    },
    getSpentOnPackageByMonth(numberOfMonth) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getSpentOnPackageByMonth.format(numberOfMonth)}`,
            withCredentials: true,
        });
    },
    getStatisticsRegisteredRechargeSameDate(numberOfCycle) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getStatisticsRegisteredRechargeSameDate.format(numberOfCycle)}`,
            withCredentials: true,
        });
    },
    getUserActivityDetail() {
        return axios({
            method: "GET",
            url: chartDataCnf.getUserActivityDetail,
            withCredentials: true,
        });
    },
    getRevenueByDateAccordingRechargeTime(numberOfDateView = 30) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getRevenueByDateAccordingRechargeTime}/${numberOfDateView}`,
            withCredentials: true,
        });
    },
    avgAmountOnRechargeTime(requestData) {
        return axios({
            method: "POST",
            url: `${chartDataCnf.avgAmountOnRechargeTime}`,
            data: requestData,
            withCredentials: true,
        });
    },
    statisticOnSpecialUser(requestData) {
        return axios({
            method: "POST",
            url: `${chartDataCnf.statisticOnSpecialUser}`,
            data: requestData,
            withCredentials: true,
        });
    },
    statisticS3TransferOutByDate(date) {
        return axios({
            method: "POST",
            url: `${chartDataCnf.statisticS3TransferOutByDate}`,
            data: { dateView: date },
            withCredentials: true,
        });
    },
    getTransferOutS3TopCostByMonth(month, year) {
        return axios({
            method: "GET",
            url: `${chartDataCnf.getTransferOutS3TopCostByMonth}/${month}/${year}`,
            withCredentials: true,
        });
    },
    getVipRechargeStatistics() {
        return axios({
            method: "POST",
            url: `${chartDataCnf.getVipRechargeStatistics}`,
            withCredentials: true,
        });
    }
};
